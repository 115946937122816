// 引入 Axios 库
import axios from 'axios'

class MyUploadAdapter {
  constructor(loader) {
    // 要在上传期间使用的文件加载器实例
    this.loader = loader
  }

  // 启动上传过程
  upload() {
    return this.loader.file
      .then(file => {
        const formData = new FormData()
        formData.append('file', file)

        // 使用 Axios 发起上传请求
        return axios.post('/api/upload/images', formData, {
          responseType: 'json',
          onUploadProgress: progressEvent => {
            // 支持上传进度
            if (progressEvent.lengthComputable) {
              this.loader.uploadTotal = progressEvent.total
              this.loader.uploaded = progressEvent.loaded
            }
          },
        }).then(response => {
          // 上传成功，从后端获取图片的URL地址
          const { data } = response.data

          return {
            default: data,
          }
        }).catch(error =>

          // 上传失败，调用reject()
          Promise.reject(`无法上传文件: ${file.name}.`))
      })
  }

  // 中止上传过程
  abort() {
    if (this.currentRequest) {
      this.currentRequest.cancel()
    }
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader =>

    // 在这里将URL配置为后端上传脚本
    new MyUploadAdapter(loader)
}

export { MyUploadAdapter, MyCustomUploadAdapterPlugin }
